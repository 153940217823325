import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_INSURANCES: 'INSURANCES/GET_ALL',
  GET_INSURANCES_SUCCESS: 'INSURANCES/GET_ALL_SUCCESS',
  GET_INSURANCES_ERROR: 'INSURANCES/GET_ALL_ERROR',
  RESET: 'INSURANCES/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INSURANCES:
      return { ...state, loading: true };
    case actionTypes.GET_INSURANCES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case actionTypes.GET_INSURANCES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getInsurances: () => async dispatch => {
    dispatch({ type: actionTypes.GET_INSURANCES });
    try {
      const { data } = await axios.get(`${Endpoints.insurances}?limit=999`);
      dispatch({type: actionTypes.GET_INSURANCES_SUCCESS, payload: { data: data }});
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: 'Não foi possível buscar as apólices no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getFiles: async (insuranceId) => {
    try {
      const { data } = await axios.get(`${Endpoints.insurances}/${insuranceId}/files?limit=999`);
      return { data };
    } catch (err) {
      return { error: 'Não foi possível buscar os arquivos da apólice no momento' };
    }
  },
  getInsuranceFileUrl: async (insuranceId, fileId) => {
    try {
      const { data } = await axios.get(`${Endpoints.insurances}/${insuranceId}/files/${fileId}`);
      return data.report;
    } catch (err) {
      return { error: 'Não foi possível buscar o arquivo' };
    }
  },
  resetInsurances: () => ({ type: actionTypes.RESET })
};