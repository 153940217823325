import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Root = () => {
    const loggedUser = useSelector((state) => state.user.data);

    const getRootUrl = () => {
        let url = loggedUser?.profiles?.includes('loan_partner') ? 'dashboard' : 'account/profile';
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
