// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Routes from './routes/Routes';
import { useMount } from './shared/hooks';
import { Creators as userCreators } from './store/ducks/user';
import jwtDecode from 'jwt-decode';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Themes
import './assets/scss/Saas.scss';

type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
    const dispatch = useDispatch();
    const [mounting, setMounting] = useState(true);
    const [saving, setSaving] = useState(false);
    useSelector(state => state.user.data); //para atualizar o App sempre que mudar os dados do usuário

    useMount(() => {
        const udata = localStorage.getItem('udata');
        if (udata) {
            const jsonData = JSON.parse(udata);
            if (jsonData) {
                dispatch(userCreators.setSession(jsonData.accessToken, jsonData.refreshToken, jsonData.data));
                /* 
                    atualiza os dados do usuário se já passou ao menos 1 dia desde o ultimo refresh, isso tbm
                    mostrará uma nova versão do Termo de Uso (ainda não aceito pelo usuário) se houver
                */
                const { exp } = jwtDecode(jsonData.accessToken);
                userCreators.updateSession(jsonData.refreshToken, exp).then((_) => {
                    const jsonData = JSON.parse(localStorage.getItem('udata'));
                    if (jsonData) {
                        dispatch(userCreators.setSession(jsonData.accessToken, jsonData.refreshToken, jsonData.data));
                    }
                });
            };
        }
        setMounting(false);
    });

    let content = '';
    if (!mounting) {
        content = <Routes />;
        const jsonData = JSON.parse(localStorage.getItem('udata'));
        if (jsonData?.newTermOfUse?.text) {
            content = <>
                {content}
                <Modal size="xl" centered show={true} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>Novos termos de uso</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{jsonData.newTermOfUse.text}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button 
                            variant="secondary" 
                            onClick={() => {userCreators.logoutAndRedirect()}}
                        >
                            Não aceito, fazer logout
                        </Button>
                        <Button 
                            variant="primary"
                            onClick={async () => {
                                setSaving(true);
                                const data = await userCreators.acceptNewTermOfUse(jsonData.data.id);
                                if (data.error) {
                                    alert(data.error);
                                } else {
                                    const jsonData = JSON.parse(localStorage.getItem('udata'));
                                    if (jsonData) {
                                        dispatch(userCreators.setSession(jsonData.accessToken, jsonData.refreshToken, jsonData.data));
                                    }                                    
                                }
                            }}
                            disabled={saving}
                        >
                            Aceito
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        }
    }

    return <main>{content}</main>;
};

export default App;
