import { combineReducers } from 'redux';

import user from './user';
import users from './users';
import layout from './layout';
import proposals from './proposals';
import messages from './messages';
import files from './files';
import insurances from './insurances';
import insurance_infos from './insurance_info';
import insurances_new from './insurances_new';


const reducers = combineReducers({
  user,
  users,
  layout,
  proposals,
  messages,
  files,
  insurances,
  insurance_infos,
  insurances_new
});

export default reducers;
