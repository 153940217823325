import moment from 'moment';

export const normalizedString = (str) => {
    return String(str)
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
        .toLowerCase();
};

export const getCPFCNPJ = (cpfcnpj, format = true) => {
    if (cpfcnpj && cpfcnpj.length > 1) {
        if (cpfcnpj[1] === '-') {
            cpfcnpj = cpfcnpj.substring(2, cpfcnpj.length - 1);
        }
        if (format) {
            if (cpfcnpj.length > 11) {
                if (cpfcnpj.length === 12) {
                    return cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4-');
                } else {
                    return cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                }
            } else {
                if (cpfcnpj.length === 9) {
                    return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3-');
                } else {
                    return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
                }
            }
        }
    }
    return cpfcnpj;
};

export const getPartnerCNPJ = (cpfcnpj, format = true) => {
    if (cpfcnpj && cpfcnpj.length > 1) {
        if (cpfcnpj[1] === '-') {
            cpfcnpj = cpfcnpj.split('-')[1];
        }
    }
    return getCPFCNPJ(cpfcnpj, format);
};

export const getPartnerCPF = (cpfcnpj, format = true) => {
    if (cpfcnpj && cpfcnpj.length > 1) {
        if (cpfcnpj[1] === '-') {
            const ar = cpfcnpj.split('-');
            if (ar.length === 3) {
                cpfcnpj = ar[2];
            }
        }
    }
    return getCPFCNPJ(cpfcnpj, format);
};

export const getCurrentDate = () => {
    const d = new Date();
    return `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
};

export function toStringDate(date) {
    if (typeof date === 'string' || date instanceof String) {
        date = new Date(date);
    }
    if (date) {
        return `0${date.getDate()}`.slice(-2) + '/' + `0${date.getMonth() + 1}`.slice(-2) + '/' + date.getFullYear();
    } else {
        return '';
    }
}

export function toStringTime(date, withSeconds) {
    if (typeof date === 'string' || date instanceof String) {
        date = new Date(date);
    }
    if (date) {
        let time = `0${date.getHours()}`.slice(-2) + (withSeconds ? ':' : 'h') + `0${date.getMinutes()}`.slice(-2);
        if (withSeconds) {
            time = `${time}:${`0${date.getSeconds()}`.slice(-2)}`;
        }
        return time;
    } else {
        return '';
    }
}

export function toStringDateTime(date, separator: String) {
    return `${toStringDate(date)}${separator ?? ' '}${toStringTime(date, false)}`;
}

export function toStringDateTimeSeconds(date, separator: String) {
    return `${toStringDate(date)}${separator ?? ' '}${toStringTime(date, true)}`;
}

export function toServerDateTime(date) {
    if (typeof date === 'string' || date instanceof String) {
        date = moment(date, 'DD/MM/YYYY');
    }
    return date.toISOString();
}

export function toStringMoney(number) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    return formatter.format(number ?? 0);
}

export function onlyNumbersString(str) {
    return str.toString().replace(/\D/g, '');
}

export function condenseName(str) {
    const arr = str.toString().split(' ');
    let index = 0;
    while (index < arr.length) {
        const condense = index > 0 && index < arr.length - 1;
        if (arr[index].length <= 2) {
            arr.splice(index, 1);
        } else {
            arr[index] = arr[index].charAt(0).toUpperCase() + (condense ? '.' : arr[index].slice(1));
            index++;
        }
    }
    return arr.join(' ');
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
        const pattern =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
};

export const stringifyFilters = (filterObj) => {
    let queryString = '';

    if (filterObj) {
        for (let prop in filterObj) {
            if (
                (Array.isArray(filterObj[prop]) && filterObj[prop].length !== 0) ||
                (!Array.isArray(filterObj[prop]) &&
                    typeof filterObj[prop] !== 'undefined' &&
                    filterObj[prop] !== null &&
                    filterObj[prop] !== '')
            ) {
                queryString += queryString ? '&' : '?';
                queryString += `${prop}=${
                    Array.isArray(filterObj[prop]) ? filterObj[prop].join(',') : filterObj[prop]
                }`;
            }
        }
    }

    return queryString;
};

export const createAttribute = (name, obj) => {
    if (!obj.hasOwnProperty(name)) {
        obj[name] = {};
    }

    return obj;
};

export const getCookie = (key) => {
    var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
};

export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

const graphNames = [
    { key: null, value: 'Não informado' },
    { key: 'bacen', value: 'Bacen' },
    { key: 'email', value: 'E-mail' },
    { key: 'fone', value: 'Fone' },
    { key: 'whatsapp', value: 'WhatsApp' },
    { key: 'loja', value: 'Loja' },
    { key: 'reclameaqui', value: 'ReclameAqui' },
    { key: 'approved', value: 'Aprovada' },
    { key: 'denied', value: 'Negada' },
    { key: 'margin', value: 'Margem' },
    { key: 'pending', value: 'Pendente' },
    { key: 'signature', value: 'Assinatura' },
    { key: 'under_analysis', value: 'Em Análise' },
    { key: 'banco', value: 'Banco' },
    { key: 'corretora', value: 'Corretora' },
    { key: 'juridico', value: 'Jurídico' },
    { key: 'ouvidoria', value: 'Ouvidoria' },
    { key: 'procon', value: 'Procon' },
    { key: 'new', value: 'Novo' },
    { key: 'refine', value: 'Refin' },
    { key: 'sale', value: 'Compra' },
    { key: 'cancelado', value: 'Cancelado' },
    { key: 'informacao', value: 'Informação' },
    { key: 'retido', value: 'Retido' },
    { key: 'transferido', value: 'Transferido' },
    { key: 'nao_contratou', value: 'Não contratou' },
    { key: 'cancelamento', value: 'Cancelamento' },
    { key: 'nao_solicitado', value: 'Não solicitado' },
    { key: 'analise', value: 'Análise' },
    { key: 'autorizado', value: 'Autorizado' },
    { key: 'evidencia', value: 'Evidência' },
    { key: 'nao_autorizado', value: 'Não autorizado' },
    { key: 'reembolsado', value: 'Reembolsado' },
    { key: 'sem_debitos', value: 'Sem débitos' },
];

export const formateGraphNames = (name) => {
    const item = graphNames.find((item) => name === item.key);
    return item ? item.value : name;
};

export const formateDataNames = (input) => {
    const shortDatePattern = /^[a-zA-Z]{3}\/\d{2}$/;

    if (shortDatePattern.test(input)) {
        const months = {
            jan: 'Jan',
            feb: 'Fev',
            mar: 'Mar',
            apr: 'Abr',
            may: 'Mai',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Ago',
            sep: 'Set',
            oct: 'Out',
            nov: 'Nov',
            dec: 'Dez',
        };

        const [month, year] = input.toLowerCase().split('/');
        const formattedMonth = months[month];

        return formattedMonth ? `${formattedMonth}/${year}` : input;
    }

    const fullMonths = {
        January: 'Janeiro',
        February: 'Fevereiro',
        March: 'Março',
        April: 'Abril',
        May: 'Maio',
        June: 'Junho',
        July: 'Julho',
        August: 'Agosto',
        September: 'Setembro',
        October: 'Outubro',
        November: 'Novembro',
        December: 'Dezembro',
    };

    if (fullMonths[input]) {
        return fullMonths[input];
    }

    return input;
};

const systemColors = [
    { key: null, value: '#000000' },
    { key: 'approved', value: '#4CAF50' },
    { key: 'denied', value: '#F44336' },
    { key: 'margin', value: '#004C30' },
    { key: 'pending', value: '#FF9800' },
    { key: 'signature', value: '#5D6B00' },
    { key: 'under_analysis', value: '#00BCD4' },
    { key: 'bacen', value: '#3F51B5' }, // Indigo
    { key: 'email', value: '#9C27B0' }, // Purple
    { key: 'fone', value: '#673AB7' }, // Deep Purple
    { key: 'whatsapp', value: '#8BC34A' }, // Light Green
    { key: 'loja', value: '#FFC107' }, // Amber
    { key: 'reclameaqui', value: '#795548' }, // Brown
    { key: 'banco', value: '#FF5722' }, // Deep Orange
    { key: 'corretora', value: '#607D8B' }, // Blue Grey
    { key: 'juridico', value: '#009688' }, // Teal
    { key: 'ouvidoria', value: '#E91E63' }, // Pink
    { key: 'procon', value: '#03A9F4' }, // Light Blue
];

export const matchColor = (name) => {
    const item = systemColors.find((item) => name === item.key);
    return item ? item.value : null;
};

const weekDays = [
    { key: 'Sunday', value: 'Domingo' },
    { key: 'Monday', value: 'Segunda' },
    { key: 'Tuesday', value: 'Terça' },
    { key: 'Wednesday', value: 'Quarta' },
    { key: 'Thursday', value: 'Quinta' },
    { key: 'Friday', value: 'Sexta' },
    { key: 'Saturday', value: 'Sábado' },
];

export const matchWeekDays = (name) => {
    const item = weekDays.find((item) => name === item.key);
    return item ? item.value : null;
};
