export default class Endpoints {
    static token = '/token/';
    static refreshToken = '/token/refresh/';
    static user_reset_password = '/user_reset_password/';
    static recoveryPassword = '/auth/users/reset_password/';
    static resetPassword = '/auth/users/reset_password_confirm/';
    static users = '/user_info';
    static user_emails = '/emails';
    static user_email = '/user_email/';
    static user_is_gasp = '/user_is_gasp/';
    static proposals = '/proposals';
    static proposal_notes = '/proposal_notes';
    static proposals_info = '/proposals_info';
    static proposals_count = '/proposals_count';
    static messages = '/messages';
    static files = '/products_files';
    static insurances = '/insurance';
    static insurance_info = '/insurance_info';
    static terms_of_use = '/terms_of_use';
}
