import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_INSURANCES: 'INSURANCES/GET_ALL',
  GET_INSURANCES_SUCCESS: 'INSURANCES/GET_ALL_SUCCESS',
  GET_INSURANCES_ERROR: 'INSURANCES/GET_ALL_ERROR',
  RESET: 'INSURANCES/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INSURANCES:
      return { ...state, loading: true };
    case actionTypes.GET_INSURANCES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case actionTypes.GET_INSURANCES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

const sleep = ms => new Promise(r => setTimeout(r, ms));

export const Creators = {
  getInsurances: () => async dispatch => {
    dispatch({ type: actionTypes.GET_INSURANCES });
    try {
      await sleep(1000);
      const data = {
        count: 2,
        next: null,
        previous: null,
        results: [
          {
              "id": 1,
              "description": "Apólice 123 - 100 Vidas - Valor total estimado em R$1.000,00 mensais",
              "active": true,
              "insurance_number": 1405,
              "coverage": "229-005"
          },
          {
              "id": 2,
              "description": "Apólice 456 - 50 Vidas - Valor total estimado em R$2.000,00 mensais",
              "active": true,
              "insurance_number": 1404,
              "coverage": "282-001"
          },
        ]
      }
      dispatch({type: actionTypes.GET_INSURANCES_SUCCESS, payload: { data: data }});
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: 'Não foi possível buscar as apólices no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_INSURANCES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getCompetencies: async (insuranceId) => {
    try {
      await sleep(1000);
      return {
        data: {
          count: insuranceId === 1 ? 2 : 0,
          next: null,
          previous: null,
          results: insuranceId === 1 ? [
            {
              id: 3,
              total_amount: 1000,
              description: 'Outubro / 2022',
              lifes_count: 100,
              report: 'https://storage.googleapis.com/aspecirlv/files/RsmPPHtaUpCDa6zXpnLG69tp9SKuInSDLxboPPe5.pdf',
              lifes_paided: {
                amount: 0,
                count: 0,
              }
            },
            {
              id: 2,
              total_amount: 1000,
              description: 'Setembro / 2022',
              lifes_count: 100,
              report: 'https://storage.googleapis.com/aspecirlv/files/RsmPPHtaUpCDa6zXpnLG69tp9SKuInSDLxboPPe5.pdf',
              lifes_paided: {
                amount: 840,
                count: 90,
              }
            },
            {
              id: 1,
              total_amount: 1000,
              description: 'Agosto / 2022',
              lifes_count: 100,
              report: 'https://storage.googleapis.com/aspecirlv/files/RsmPPHtaUpCDa6zXpnLG69tp9SKuInSDLxboPPe5.pdf',
              lifes_paided: {
                amount: 1000,
                count: 100,
              }
            },
          ] : []
        }
      };
    } catch (err) {
      return { error: 'Não foi possível buscar os arquivos da apólice no momento' };
    }
  },
  getInsuranceFileUrl: async (insuranceId, fileId) => {
    try {
      const { data } = await axios.get(`${Endpoints.insurances}/${insuranceId}/files/${fileId}`);
      return data.report;
    } catch (err) {
      return { error: 'Não foi possível buscar o arquivo' };
    }
  },
  resetInsurances: () => ({ type: actionTypes.RESET })
};