import axios from '../api';
import Endpoints from '../endpoints';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import logger from '../../shared/Logger';
import ErrorMessages from '../../shared/ErrorMessages';
import { onlyNumbersString } from '../../shared/utility';

export const Types = {
    LOGIN: 'user/LOGIN',
    LOGIN_FAILED: 'user/LOGIN_FAILED',
    LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
    LOGIN_ERROR_HANDLED: 'user/LOGIN_ERROR_HANDLED',

    SET_SESSION: 'user/SET_SESSION',

    UPDATE_SESSION: 'user/UPDATE_SESSION',
    UPDATE_SESSION_SUCCESS: 'UPDATE_SESSION_SUCCESS',
    UPDATE_SESSION_FAILED: 'user/UPDATE_SESSION_FAILED',

    UPDATE_REFRESH_TOKEN: 'user/UPDATE_REFRESH_TOKEN',
    UPDATE_REFRESH_TOKEN_SUCCESS: 'user/UPDATE_REFRESH_TOKEN_SUCCESS',
    UPDATE_REFRESH_TOKEN_FAILED: 'user/UPDATE_REFRESH_TOKEN_FAILED',

    TOKEN_REQUEST: 'user/TOKEN_REQUEST',
    TOKEN_FAILED: 'user/TOKEN_FAILED',
    TOKEN_SUCCESS: 'user/TOKEN_SUCCESS',

    RECOVERY: 'user/RECOVERY',
    RECOVERY_SUCCESS: 'user/RECOVERY_SUCCESS',
    RECOVERY_FAILED: 'user/RECOVERY_FAILED',
    RECOVERY_ERROR_HANDLED: 'user/RECOVERY_ERROR_HANDLED',
    RECOVERY_CODE_SENT_RESET: 'user/RECOVERY_CODE_SENT_RESET',

    RESET: 'user/RESET',
    RESET_FAILED: 'user/RESET_FAILED',
    RESET_SUCCESS: 'user/RESET_SUCCESS',
    RESET_ERROR_HANDLED: 'user/RESET_ERROR_HANDLED',

    GET: 'user/GET',
    GET_SUCCESS: 'user/GET_SUCCESS',
    GET_FAILED: 'user/GET_FAILED',

    UPDATE: 'user/UPDATE',
    UPDATE_SUCCESS: 'user/UPDATE_SUCCESS',
    UPDATE_FAILED: 'user/UPDATE_FAILED',

    UPDATE_PASSWORD: 'user/UPDATE_PASSWORD',
    UPDATE_PASSWORD_SUCCESS: 'user/UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILED: 'user/UPDATE_PASSWORD_FAILED',
    UPDATE_PASSWORD_RESET: 'user/UPDATE_PASSWORD_RESET',

    LOGOUT: 'user/LOGOUT',
};

const initialState = {
    accessToken: null,
    refreshToken: null,
    profile: {
        data: null,
        loading: false,
        error: null,
    },
    password: {
        loading: false,
        error: null,
        data: null,
    },

    login: {
        loading: false,
        error: null,
    },
    recovery: {
        loading: false,
        error: null,
        codeSent: false,
    },
    reset: {
        loading: false,
        error: null,
        redirect: false,
    },
    updateSession: {
        loading: false,
    },
    refreshTokenUpdate: {
        loading: false,
    },
};

export default function User(state = initialState, action) {
    switch (action.type) {
        case Types.LOGIN:
            return { ...state, login: { ...state.login, loading: true } };
        case Types.LOGIN_FAILED:
            return { ...state, login: { ...state.login, loading: false, error: action.payload.error } };
        case Types.LOGIN_SUCCESS:
            return { ...state, ...action.payload, login: { ...state.login, loading: false } };
        case Types.LOGIN_ERROR_HANDLED:
            return { ...state, login: { ...state.login, error: null } };
        case Types.SET_SESSION:
            return { ...state, ...action.payload };
        case Types.UPDATE_SESSION:
            return { ...state, updateSession: { loading: true } };
        case Types.UPDATE_SESSION_SUCCESS:
            return { ...state, updateSession: { loading: false }, ...action.payload };
        case Types.UPDATE_SESSION_FAILED:
            return { ...state, updateSession: { loading: false } };
        case Types.UPDATE_REFRESH_TOKEN:
            return { ...state, refreshTokenUpdate: { loading: true } };
        case Types.UPDATE_REFRESH_TOKEN_SUCCESS:
            return { ...state, refreshTokenUpdate: { loading: false }, ...action.payload };
        case Types.UPDATE_REFRESH_TOKEN_FAILED:
            return { ...state, refreshTokenUpdate: { loading: false } };
        case Types.RECOVERY:
            return { ...state, recovery: { ...state.recovery, loading: true } };
        case Types.RECOVERY_SUCCESS:
            return { ...state, recovery: { ...state.recovery, error: null, loading: false, codeSent: true } };
        case Types.RECOVERY_FAILED:
            return {
                ...state,
                recovery: { ...state.recovery, loading: false, error: action.payload.error },
            };
        case Types.RECOVERY_ERROR_HANDLED:
            return { ...state, recovery: { ...state.recovery, error: null } };
        case Types.RECOVERY_CODE_SENT_RESET:
            return { ...state, recovery: { ...state.recovery, codeSent: false } };
        case Types.RESET:
            return { ...state, reset: { ...state.reset, loading: true } };
        case Types.RESET_FAILED:
            return { ...state, reset: { ...state.reset, loading: false, error: action.payload.error } };
        case Types.RESET_SUCCESS:
            return { ...state, ...action.payload, reset: { ...state.reset, loading: false, redirect: true } };
        case Types.RESET_ERROR_HANDLED:
            return { ...state, reset: { ...state.reset, error: null } };

        case Types.GET:
            return {
                ...state,
                profile: {
                    ...initialState.profile,
                    loading: true,
                }

            };

        case Types.GET_SUCCESS:
            return {
                ...state,
                profile: {
                    loading: false,
                    error: null,
                    data: action.payload.data
                }
            };
        case Types.GET_FAILED:
            return {
                ...state,
                loading: false,
                profile: {
                    loading: false,
                    data: null,
                    error: action.payload.error
                }
            };
        case Types.UPDATE:
            return {
                ...state,
                profile: {
                    ...initialState.profile,
                    loading: true,
                }

            };

        case Types.UPDATE_SUCCESS:
            return {
                ...state,
                profile: {
                    loading: false,
                    error: null,
                    data: action.payload.data
                }
            };
        case Types.UPDATE_FAILED:
            return {
                ...state,
                loading: false,
                profile: {
                    loading: false,
                    data: null,
                    error: action.payload.error
                }
            };

        case Types.UPDATE_PASSWORD:
            return {
                ...state,
                password: {
                    loading: true,
                    data: null,
                    error: null
                }
            };

        case Types.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                password: {
                    loading: false,
                    error: null,
                    data: action.payload.data
                }
            };
        case Types.UPDATE_PASSWORD_FAILED:
            return {
                ...state,
                password: {
                    loading: false,
                    error: action.payload.error,
                    data: null
                }
            };

        case Types.UPDATE_PASSWORD_RESET:
            return {
                ...state,
                password: {
                    ...initialState.password
                }
            };
        case Types.LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export const Creators = {
    setSession: (accessToken, refreshToken, data) => dispatch => {
        const { exp } = jwtDecode(accessToken);
        dispatch({ type: Types.SET_SESSION, payload: { accessToken, refreshToken, data } });
        dispatch(Creators.checkUpdateSession(refreshToken, exp));
    },
    updateSession: async (refreshToken, exp) => {
        const { data } = await axios.post(Endpoints.refreshToken, {
            refresh: refreshToken,
        });
        const accessToken = data.access;
        const { user_id } = jwtDecode(accessToken);
        const userData = await Creators.getUserData(user_id, accessToken);
        const newTermOfUse = await Creators.getHasNewTermOfUse(user_id, accessToken);
        const newData = {
            accessToken: accessToken,
            refreshToken: data.refresh,
            lastRefresh: (new Date()).toISOString(),
            newTermOfUse: { ...newTermOfUse },
            data: { ...userData },
        };
        localStorage.setItem('udata', JSON.stringify(newData));
        return data;
    },
    checkUpdateSession: (refreshToken, exp) => dispatch => {
        const now = moment();
        const expiration = moment.unix(exp);
        setTimeout(async () => {
            try {
                dispatch({ type: Types.UPDATE_SESSION });
                const data = await Creators.updateSession(refreshToken, exp);
                const decodedJwt = jwtDecode(data.access);
                dispatch({
                    type: Types.UPDATE_SESSION_SUCCESS,
                    payload: { accessToken: data.access, refreshToken: data.refresh },
                });
                dispatch(Creators.checkUpdateSession(data.refresh, decodedJwt.exp));
            } catch (err) {
                dispatch({ type: Types.UPDATE_SESSION_FAILED });
                dispatch(Creators.logout());
            }
        }, expiration.diff(now));
    },
    getUserData: async (userId, accessToken) => {
        const authorization = `Bearer ${accessToken}`;
        const userResponse = await axios.get(`${Endpoints.users}/${userId}/`, { headers: { authorization } });
        return {
            id: userId,
            ...userResponse.data,
        };
    },
    getHasNewTermOfUse: async (userId, accessToken) => {
        const authorization = `Bearer ${accessToken}`;
        const termOfUse = await axios.get(`${Endpoints.terms_of_use}?has_new_term=True`, { headers: { authorization } });
        const results = termOfUse.data.results ?? [];
        if (results.length > 0) {
            return results[0];
        }
        return {};
    },
    acceptNewTermOfUse: async (userId) => {
      try {
        const savedData = JSON.parse(localStorage.getItem('udata'));
        const newVersion = savedData?.newTermOfUse?.version;
        if (newVersion) {
            const data  = axios.patch(`${Endpoints.users}/${userId}`, {last_term_of_use_accepted: newVersion});
            const newData = {
                ...savedData,
                newTermOfUse: {},
                data: {
                    ...savedData.data,
                    last_term_of_use_accepted: newVersion
                },
            };
            localStorage.setItem('udata', JSON.stringify(newData));
            return { data };
        }
      } catch (err) {
        return { error: 'Ocorreu um erro ao tentar marcar o termo de uso como lido. Por favor, tente novamente' };
      }
      return {};
    },
    login: (cnpj, cpf, password, persistSession) => async dispatch => {
        dispatch({ type: Types.LOGIN });
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const formData = new FormData();
            cnpj = onlyNumbersString(cnpj);
            cpf = onlyNumbersString(cpf);
            formData.append('cpfcnpj', `${cpf.length === 0 ? '2' : '3'}-${cnpj}-${cpf}`);
            formData.append('password', password);
            formData.append('ip_address', 'portal-parceiro');
            const { data } = await axios.post(Endpoints.token, formData, config);

            const accessToken = data.access;
            const refreshToken = data.refresh;
            const { user_id, exp } = jwtDecode(accessToken);

            // user
            const userData = await Creators.getUserData(user_id, accessToken);
            const newTermOfUse = await Creators.getHasNewTermOfUse(user_id, accessToken);
            if (persistSession) {
                localStorage.setItem(
                    'udata',
                    JSON.stringify({
                        accessToken,
                        refreshToken,
                        lastRefresh: (new Date()).toISOString(),
                        newTermOfUse: { ...newTermOfUse },
                        data: { ...userData },
                    }),
                );
            }

            dispatch(Creators.checkUpdateSession(refreshToken, exp));
            dispatch({
                type: Types.LOGIN_SUCCESS,
                payload: {
                    accessToken,
                    refreshToken,
                    data: { ...userData },
                },
            });
        } catch (err) {
            logger.error(err.message);
            const { response } = err;
            if (response && (response.status === 401 || response.data.is_gasp)) {
                dispatch({ type: Types.LOGIN_FAILED, payload: { error: 'Usuário e/ou senha inválidos' } });
            } else {
                dispatch({ type: Types.LOGIN_FAILED, payload: { error: ErrorMessages.serviceUnavailable } });
            }
        }
    },
    updateUser: async (userId, fields) => {
      try {
        const { data } = await axios.put(`${Endpoints.users}/${userId}`, fields);
        const savedData = JSON.parse(localStorage.getItem('udata'));
        const newData = {
            ...savedData,
            data: {...fields},
        };
        if (savedData) {
            localStorage.setItem('udata', JSON.stringify(newData));
        }
        return { data };
      } catch (err) {
        return { error: 'Ocorreu um erro ao tentar atualizar o usuário. Por favor, tente novamente' };
      }
    },
    updatePassword: async (userId, currentPass, newPass) => {
      try {
        await axios.post(`/auth/users/set_password/`, {
            'current_password': currentPass,
            'new_password': newPass,
            're_new_password': newPass,
        });        
        return {};
      } catch (err) {
        const defaultError = 'Ocorreu um erro ao tentar atualizar sua senha. Por favor, tente novamente';
        return err?.response?.data ?? {
            'current_password': defaultError,
            'new_password': defaultError,
            're_new_password': defaultError,
        };
      }
    },
    getUserIsGasp: async (uid) => {
        try {
            return await axios.get(`${Endpoints.user_is_gasp}?${uid}`);
        } catch (err) {
            return {
                data: {
                    is_gasp: false,
                }
            };
        }
    },
    getUserEmail: async (cpfcnpj) => {
        try {
            return await axios.get(`${Endpoints.user_email}?2-${cpfcnpj}-`);
        } catch (err) {
            const error = err?.response?.data?.error ?? 'Ocorreu algum problema. Por favor, tente novamente';
            return { error };
        }
    },
    recovery: cpfcnpj => async dispatch => {
        dispatch({ type: Types.RECOVERY });
        try {
            await axios.post(Endpoints.recoveryPassword, {
                'cpfcnpj': `2-${cpfcnpj}-`,
                'email': 'a@a.com', //fake email for validation
            });
            dispatch({ type: Types.RECOVERY_SUCCESS });
        } catch (err) {
            logger.error(err.message);
            dispatch({ type: Types.RECOVERY_FAILED, payload: { error: ErrorMessages.serviceUnavailable } });
        }
    },
    reset: async (uid, token, new_password, re_new_password) => {
        try {
            await axios.post(Endpoints.resetPassword, {
                uid, token, new_password, re_new_password
            });
        } catch (err) {
            logger.error(err.message);
            const { response } = err;
            if (response && response.status === 400) {
                if (response.data.new_password)
                    return { error: response.data.new_password[0] };
                else
                    return { error: 'Dados inválidos' };
            } else {
                return { error: ErrorMessages.serviceUnavailable };
            }
        }
    },
    resetPasswordForUser: async (userId) => {
      try {
        const { data } = await axios.post(Endpoints.user_reset_password, {user_id: userId});
        return { data };
      } catch (err) {
        return { error: 'Ocorreu um erro ao tentar resetar a senha do usuário. Por favor, tente novamente' };
      }
    },
    resetRecovery: () => ({ type: Types.RECOVERY_CODE_SENT_RESET }),
    handleLoginError: () => ({ type: Types.LOGIN_ERROR_HANDLED }),
    handleRecoveryError: () => ({ type: Types.RECOVERY_ERROR_HANDLED }),
    handleResetError: () => ({ type: Types.RESET_ERROR_HANDLED }),
    logout: () => dispatch => {
        localStorage.removeItem('udata');
        localStorage.removeItem('report');
        dispatch({ type: Types.LOGOUT });
    },
    logoutAndRedirect: () => {
        localStorage.removeItem('udata');
        localStorage.removeItem('report');
        window.location.href = "/";
    },
};
